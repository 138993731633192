/* Set same height for cards */
.default--card-equal-height {
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px - 10px);
}

.default--card-equal-height .card-footer {
  margin-top: auto;
}

.ios--card-equal-height {
  display: flex;
  flex-direction: column;
  height: calc(100% - 24px - 24px);
}

.ios-default--card-equal-height .card-footer {
  margin-top: auto;
}

/* Lists without margin */
.no-margin-list {
  margin: 0;
}

/* Delete button */
.delete-button {
  color: var(--ion-color-danger) !important;
}

/* Unset the height for the chip component */
.unset-chip-height {
  height: unset;
}

/* Add bottom margin for p tag */
.paragraph-margin-bottom {
  margin-bottom: 20px !important;
}

/* Add top margin for img tag */
.image-margin-top {
  margin-top: 20px !important;
}

/* Table */
table {
  min-width: 100%;
}

th, td {
  text-align: left;
  padding: 5px;
  border-right: 1px solid var(--ion-color-light);
  white-space: nowrap;
}

th.center, td.center {
  text-align: center;
}

th:last-child, td:last-child {
  border-right: none;
}

th {
  border-bottom: 1px solid var(--ion-color-light);
  text-transform: uppercase;
}

td {
  color: rgba(var(--ion-color-light-contrast-rgb));
}

.table {
  padding: 5px;
  overflow-x: auto;
}

.table-multi-line {
  padding: 5px;
}

.table-multi-line td {
  vertical-align: top;
  white-space: pre-line;
  word-break: break-all;
}

.table-multi-line td.nowrap {
  white-space: nowrap;
  word-break: keep-all;
}

td.link {
  cursor: pointer;
}

.container-details-link {
  cursor: pointer;
  background: transparent;
  color: var(--ion-color-primary);
}

/* Scroll to bottom button in editor */
.editor-scroll-to-bottom-button {
  position: absolute;
  z-index: 999;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

/* Center a spinner element in the middle of the screen */
.spinner-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -14px;
  margin-left: -14px;
}

/* Increase width of modals for larger sized screens */
@media only screen and (min-width: 768px) and (min-height: 600px) {
  .modal-wrapper {
    --width: 90%;
    --height: 90%;
  }
}

/* Set the border radius of the IonAvatar components to 0.
   We only show the hexagon in the avatar component and with the border radius this looks ugly because of the white
   border gets cut of */
ion-avatar {
  --border-radius: 0px;
}

/* Center a header image in the card header and set the background color to the used primary color */
.card-header-image {
  text-align: center;
  background-color: var(--ion-color-primary);
}

.card-header-image img {
  margin: auto;
}

/* Add a border to the bottom of IonCardContent if a IonCard component contains multiple IonCardContent components. */
.ion-card-content-divider {
  border-bottom: 1px solid var(--ion-color-light);
}

/* Menu customization
   On desktop the menu should always have a width of 400px, Therefor we are setting the minimum and maximum width of the
   IonSplitPane. 400px corresponds to one quater of the default Electron window size.
   To fit the menu we set the width of the cluster select box to 100% and the width of the popover to 400px - 32px
   (the padding left + right). */
.menu-width {
  --side-max-width: 400px;
  --side-min-width: 400px;
  --side-width: 400px;
}

.menu-cluster-select {
  max-width: 100%;
  width: 100%;
}

.menu-cluster-select .popover-content {
  width: 368px;
}

/* Select Kubeconfig
   Customization for the file input to import a Kubeconfig file */
.select-kubeconfig-wrapper {
  padding: 16px;
}

.select-kubeconfig {
  width: 100%;
  cursor: pointer;
}

/* Terminal
   Customization for the terminal */
.terminal-tab-close-button {
  --padding-start: 0px;
  --padding-end: 0px;
  padding-top: 10px;
  margin-left: -30px;
  color: rgba(var(--ion-color-light-contrast-rgb), 0.6);
}

.terminal-tab-label {
  max-width: calc(100% - 35px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.terminal-tab-close-button-color.active {
  color: var(--ion-color-primary);
}

.terminal-tab-close-button-color {
  color: rgba(var(--ion-color-light-contrast-rgb), 0.6);
}

/* Fix layout for using IonLabel together with IonRange in IonItem
   without this the IonLabel height is cutted to 15px */
.label-for-range {
  min-height: 18px;
}

/* Tooltip
   The tooltip css classes can be used to show additional information via tooltip, when a user hovers a component on
   desktop. We are using the same colors as the IonToast component uses as background color and color. */
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--ion-color-step-800, #333333);
  color: var(--ion-color-step-50, #f2f2f2);
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Authentication Wrapper */
.auth-col {
  text-align: center;
}

.auth-img {
  max-width: 256px;
}

.auth-padding-top {
  padding-top: 100px;
}

.auth-unlock-button {
  max-width: 512px;
  margin: auto;
}
